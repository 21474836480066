import React from "react";
import styles from "./BtnBlog.module.scss";
import PropTypes from "prop-types";

const BtnBlog = ({ name, href }) => {
  return (
  <a className={styles.btnblog} href={href}>{name}</a>
  );
};

BtnBlog.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default BtnBlog;
