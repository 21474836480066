import React from "react";
import styles from "./blog-categories.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";

// const leftSmoke =
//   "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(4, 230, 167, 0.22) 0%, rgba(4, 6, 20, 0) 100%)";

// const rightSmoke =
//   "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(4, 230, 167, 0.22) 0%, rgba(4, 6, 20, 0) 100%)";

const BlogCategories = props => {
  const { t } = useTranslation();
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", stiffness: 20 }}
      className={styles.section}
    >
      {/* backgrounds 
      <div className={styles.leftSmoke}>
        <SmokeLight
          background={leftSmoke}
          width={breakpoints.sm ? 700 : 2000}
          height={breakpoints.sm ? 500 : 1600}
        />
      </div>
      <div className={styles.rightSmoke}>
        <SmokeLight
          background={rightSmoke}
          width={breakpoints.sm ? 700 : 2000}
          height={breakpoints.sm ? 500 : 1600}
        />
      </div>
      {/* end backgrounds */}

      <div className={styles.section_row}>
        <div className={styles.section_row_col}>
          <p className='n5you'>{t("CONTENT.FEATURING.TEXT")}</p>
          <div className={styles.section_row_col_btnsContainer}>
            {props.children}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default BlogCategories;
