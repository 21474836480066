import * as React from "react";
import styles from "./btn-show-more.module.scss"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FiChevronRight } from "react-icons/fi";

// import arrow from "../../../assets/svg/angle_down_white.svg";

function BtnShowMore({ onClick }) {
	const { t } = useTranslation();
	return (
		<button onClick={onClick} className={styles.btn}>
			{t("SHOWMORE")}
			<FiChevronRight style={{fontSize: "1.5rem"}}/> 
			{/* <img
              src={arrow}
              alt='Arrow'
              style={{  paddingLeft: 15 }}
			  loading="lazy"
            /> */}
		</button>
	);
}

export default BtnShowMore;
