import React from "react";
import styles from "./ArticleCardY.module.scss";
import PropTypes from "prop-types";
import Clock from "../../../assets/svg/Clock.svg";
import Calendar from "../../../assets/svg/Calendar.svg";
import { useI18next, Link } from "gatsby-plugin-react-i18next";

const ArticleCardY = ({
  title,
  description,
  date,
  time,
  bgImg,
  el,
  blogArr,
}) => {
  const { language } = useI18next();
  const isPreviousToLaunch = date => new Date(date) < new Date('2021-05-31')
  return (
    <>
      <div className={styles.col}>
        <Link
          to={`/content/${el.slug}/`}
          language={language}
          state={{
            item: el,
            blogsArray: blogArr.filter(n => n.id !== el.id),
          }}
          style={{ color: "inherit" }}
        >
          <div
            className={styles.col_img}
            style={{
              background: `linear-gradient(195.51deg, rgba(0, 0, 0, 0.04) -38.35%, #070E20 89.14%), url(${bgImg}) `,
              backgroundSize: "cover",
            }}
          ></div>
          <p className={styles.col_title}>{title}</p>
          <p className={styles.col_subtitle}>{description}</p>
          <div className={styles.col_datetime}>
            {
              !isPreviousToLaunch(date) && (
                <>
                  <img src={Calendar} alt='Calendar Icon' loading="lazy"/>{" "}
                  <span>{date.slice(0, 10).split("-").reverse().join("-")}</span>
                </>
              )
            }
            <img src={Clock} alt='Clock Icon' loading="lazy"/> <span>{time}</span>
          </div>
        </Link>
      </div>
    </>
  );
};

ArticleCardY.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ArticleCardY;
