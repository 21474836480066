import React from "react";
import BtnBlog from "@molecules/BtnBlog";
import ArticleCardY from "@molecules/ArticleCardY";
import ArticleContainer from "./article-container";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import BlogCategories from "@molecules/BlogCategories";
import styles from "./articles-list.module.scss";
import BtnShowMore from "@atoms/BtnShowMore";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";

const hide = { opacity: 0, y: 20 };
const show = { opacity: 1, y: 0 };
const transition = {
  type: "spring",
  stiffness: 20,
};

const YoutubeChannel = ({
  handleMoreCeos,
  ceosInZoomBlogs,

  handleMoreWomen,
  womenBlogs,  

  handleMoreNowKnow,
  nowBlogs,  

  handleMoreInsights,
  n5InsightsBlogs,  

  handleMoreDirectors,
  directorsBlogs,  

  handleMoreQuestions,
  questionsBlogs,  

  handleMoreCourses,
  coursesBlogs,  

  handleMoreFeatureA,
  featureArticlesBlogs,

  handleMoreLearning,
  learningN5MinutesBlogs,

  handleMorePistacchioAsk,
  asksBlogs
}) => {
  const [ceos, ceosVisible] = useOnScreen({ threshold: 0.1 });
  const [women, womenVisible] = useOnScreen({ threshold: 0.1 });
  const [now, nowVisible] = useOnScreen({ threshold: 0.1 });
  const [insights, insightsVisible] = useOnScreen({ threshold: 0.1 });
  const [questions, questionsVisible] = useOnScreen({ threshold: 0.1 });
  const [courses, coursesVisible] = useOnScreen({ threshold: 0.1 });  
  const [articles, articlesVisible] = useOnScreen({ threshold: 0.1 });
  const [minute, minuteVisible] = useOnScreen({ threshold: 0.1 });
  const [asks,asksVisible] = useOnScreen({ threshold: 0.1});
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <motion.div layout transition={{ duration: 0.2 }} className={styles.bg}>
      <BlogCategories>
        <BtnBlog name={t("CONTENT.FEATURING.CEOS")} href='#CEOSINZOOM' />
        <BtnBlog name={t("CONTENT.FEATURING.WOMEN")} href='#WOMENXN5' />
        {/* <BtnBlog name={t("CONTENT.FEATURING.TEAM")} href='#N5TEAM' /> OPEN FINANCE */}
        <BtnBlog name={t("CONTENT.FEATURING.INSIGHTS")} href='#N5INSIGHTS' />
        <BtnBlog name={t("CONTENT.FEATURING.PISTACCHIO")} href="#PISTACCHIO" />
        <BtnBlog name={t("CONTENT.FEATURING.NOW")} href='#NOWYOUKNOW' />        
        <BtnBlog name={t("CONTENT.FEATURING.QUESTIONS")} href='#N5QUESTIONS' />
        <BtnBlog name={t("CONTENT.FEATURING.FEATURE")} href='#ARTICLES' />
        <BtnBlog name={t("CONTENT.FEATURING.MINUTE")} href='#MINUTE' />
        <BtnBlog name={t("CONTENT.DOWNLOAD.TITLE")} href='#download' />
        <BtnBlog name={t("CONTENT.FEATURING.COURSES")} href='#COURSES' />
      </BlogCategories>

      <motion.div
        ref={ceos}
        initial={hide}
        animate={ceosVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.CEOS")} id='CEOSINZOOM' contentUrl="/content/ceos-in-zoom">
          {ceosInZoomBlogs.length > 0 &&
            ceosInZoomBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={ceosInZoomBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreCeos} />        
      </motion.div>

      <motion.div
        ref={women}
        initial={hide}
        animate={womenVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.WOMEN")} id='WOMENXN5' contentUrl="/content/meet-the-team">
          {womenBlogs.length > 0 &&
            womenBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={womenBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreWomen} />        
      </motion.div>

      {/* <motion.div
        ref={directors}
        initial={hide}
        animate={directorsVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer
          name={t("CONTENT.FEATURING.TEAM")}
          id='N5TEAM'
          contentUrl="/content/open-finance"
        >
          {directorsBlogs.length > 0 &&
            directorsBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={directorsBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreDirectors} />        
      </motion.div> */}

      <motion.div
        ref={insights}
        initial={hide}
        animate={insightsVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer
          name={t("CONTENT.FEATURING.INSIGHTS")}
          id='N5INSIGHTS'
          contentUrl="/content/n5-insights"
        >
          {n5InsightsBlogs.length > 0 &&
            n5InsightsBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={n5InsightsBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreInsights} />        
      </motion.div>

      <motion.div
        ref={asks}
        initial={hide}
        animate={asksVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.PISTACCHIO")} id='PISTACCHIO' contentUrl="/content/pistacchio-ask">
          {asksBlogs.length > 0 &&
            asksBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={asksBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMorePistacchioAsk} />        
      </motion.div>

      <motion.div
        ref={now}
        initial={hide}
        animate={nowVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.NOW")} id='NOWYOUKNOW' contentUrl="/content/now-you-know">
          {nowBlogs.length > 0 &&
            nowBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={nowBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreNowKnow} />        
      </motion.div>

      <motion.div
        ref={questions}
        initial={hide}
        animate={show}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer
          name={t("CONTENT.FEATURING.QUESTIONS")}
          id='N5QUESTIONS'
          contentUrl="/content/more-about-n5"
        >
          {questionsBlogs.length > 0 &&
            questionsBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={questionsBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreQuestions} />        
      </motion.div>

      <motion.div
        ref={articles}
        initial={hide}
        animate={articlesVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.FEATURE")} id='ARTICLES' contentUrl="/content/feature-articles">
          {featureArticlesBlogs.length > 0 &&
            featureArticlesBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={featureArticlesBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreFeatureA} />        
      </motion.div>

      <motion.div
        ref={minute}
        initial={hide}
        animate={minuteVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.MINUTE")} id='MINUTE' contentUrl="/content/learning-n5-minutes">
          {learningN5MinutesBlogs.length > 0 &&
            learningN5MinutesBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={learningN5MinutesBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreLearning} />        
      </motion.div>

      <motion.div
        ref={courses}
        initial={hide}
        animate={coursesVisible ? show : hide}
        transition={transition}
        className={styles.showMore}
      >
        <ArticleContainer name={t("CONTENT.FEATURING.COURSES")} id='COURSES' contentUrl="/content/others">
          {coursesBlogs.length > 0 &&
            coursesBlogs.map(el => (
              <ArticleCardY
                key={el.published_at}
                bgImg={el.hero_image.url}
                title={el.post_translations[language]?.title}
                description={el.post_translations[language]?.description}
                date={el.published_at}
                time={el.read_time}
                el={el}
                blogArr={coursesBlogs}
              />
            ))}
        </ArticleContainer>
        <BtnShowMore onClick={handleMoreCourses} />        
      </motion.div>
    </motion.div>
    
  );
};

export default YoutubeChannel;
