import React from "react";
import styles from "./articles-list.module.scss"
import { Link } from "gatsby";
import divider from "../../../assets/image/line6.png";

const ArticleContainer = ( props ) => {
  return (
      <section className={styles.section} id={props.id}>
        <div className={styles.section_row}>
          <div className={styles.section_row_col}>
            <Link 
              className={styles.section_row_col_name}
              to={props.contentUrl}
              style={{color: props.black && "#000000"}}
            >
              {props.name}
            </Link>
            <div className='mb65'>
              <img src={divider} alt='divider' style={{maxWidth:"100%", marginTop: "10px"}} loading="lazy"/>
            </div>
            <div className={styles.section_row}>
              {props.children}
            </div>
          </div>
        </div>
      </section>
  );
};

export default ArticleContainer;
